<template>
  <input v-bind:value="formattedValue" @input="onInput" v-mask="'(999) 999-9999'" class="form-control" type="tel" placeholder="(123) 456-7890" />
</template>

<script>
import formatPhoneNumber from "@/utils/FormHelpers/formatPhoneNumber";

export default {
  name: "PrimePhoneInput",
  props: {
    value: String,
    type: String
  },
  data() {
    return {
      rawValue: this.getValue(this.value)
    };
  },
  watch: {
    value(newValue) {
      this.rawValue = this.getValue(newValue);
    }
  },
  computed: {
    isTwilio() {
      return this.type === "twilio";
    },
    formattedValue() {
      if (!this.value) {
        return "";
      }
      return this.value.replace("+1", "").trim();
    }
  },
  methods: {
    getValue(source) {
      if (!source) return source;
      return this.isTwilio ? formatPhoneNumber(source) : source;
    },
    onInput(event) {
      this.rawValue = this.getValue(event.target.value);
      this.$emit("input", this.rawValue);
    }
  }
};
</script>
