import { send } from "@/services/Api";
// import copy from "@/assets/languagePack/english";
import store from "@/store";

export default async id => {
  const method = "get";
  const path = document.config.organizationSettings.get + id;
  try {
    const results = await send({ path, method, authToken: true });
    return results.data;
  } catch (error) {
    const errorDetails = {
      header: "There was an issue with retrieving organization settings",
      message: error
    };
    store.commit("errorHandler/toastableError", errorDetails);
  }
};
