<template>
  <IconInfoCircle class="form-tooltip-icon" v-tooltip="{ content: text, classes: fullWidth ? '' : 'max-width-300' }" />
</template>

<script>
import IconInfoCircle from "@/components/Global/Icons/IconInfoCircle";

export default {
  name: "Tooltip",
  components: { IconInfoCircle },
  props: {
    text: String,
    fullWidth: Boolean
  }
};
</script>

<style scoped>
.form-tooltip-icon {
  width: 20px;
  display: inline-block;
  vertical-align: bottom;
  fill: var(--ion-color-medium);
}

.form-tooltip-icon:hover {
  fill: var(--ion-color-medium-shade);
}
</style>
