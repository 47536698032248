<template>
  <ion-grid>
    <ion-row class="pad-bottom-thirty">
      <ion-col class="margin-bottom-eighty">
        <h3 class="text-center">{{ title }}</h3>
        <div class="prime-form-container inner-container">
          <ErrorNotification :errors="errors" />
          <form @submit.prevent="validateAndSubmit" novalidate="true">
            <div class="prime-form-group">
              <h6>Practice Name</h6>
              <input v-model.trim="practice.name" class="form-control" type="text" placeholder="SHMR Primary Care North Alston" />
            </div>
            <div class="prime-form-group">
              <h6>Practice Alias</h6>
              <input v-model.trim="practice.orgOutreachAlias" class="form-control" type="text" placeholder="Primary Care North Alston" />
            </div>
            <div class="prime-form-group">
              <h6>Address Line 1</h6>
              <input v-model.trim="practice.address1" class="form-control" type="text" placeholder="1234 North Alston Ave" />
            </div>
            <div class="prime-form-group">
              <h6>Address Line 2</h6>
              <input v-model.trim="practice.address2" class="form-control" type="text" placeholder="Suite 102" />
            </div>
            <div class="prime-form-group">
              <h6>City / State</h6>
              <input v-model.trim="practice.city" class="form-control city" type="text" placeholder="Ferguson" />
              <select v-model="practice.state" class="form-control state">
                <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
              </select>
            </div>
            <div class="prime-form-group">
              <h6>Zip Code</h6>
              <input v-model.trim="practice.zipCode" v-mask="'99999'" class="form-control" type="text" placeholder="63135" />
            </div>
            <div class="prime-form-group">
              <h6>Office Phone Number</h6>
              <PrimePhoneInput v-model.trim="practice.officeNumber" />
            </div>
            <div class="prime-form-group">
              <h6>Phone Number for LumerisEngage Support <Tooltip :text="copy.LumerisEngagePhoneNumber" /></h6>
              <PrimePhoneInput v-model.trim="practice.supportPhoneNumber" />
            </div>
            <div class="prime-form-group">
              <h6>Email Address For Task Notifications <Tooltip :text="copy.taskNotificationEmail" /></h6>
              <input v-model.trim="practice.email" class="form-control" type="text" placeholder="example@email.com" />
              <div v-if="!$can(I.ACCESS_LUMERIS_ADMIN) && !isNew" class="text-center send-email" :class="{ disabled: !sendEmailEnabled }">
                <span :class="{ 'cursor-pointer': sendEmailEnabled }" @click="sendTestEmail">Send Test Email</span>
              </div>
            </div>
            <div v-if="!isNew" class="prime-form-group">
              <h6>Timezone</h6>
              <select v-model="practice.timeZone" class="form-control">
                <option v-for="timezone in timezones" :key="timezone.value" :value="timezone.value">{{ timezone.display }}</option>
              </select>
            </div>
            <div class="prime-form-group" v-if="!isNew">
              <h6>Outreach Phone Number <Tooltip :text="copy.outreachPhoneNumber" /></h6>
              <PrimePhoneInput v-model="practice.phoneNumber" type="twilio" :disabled="!$can(I.ACCESS_LUMERIS_ADMIN)" />
            </div>
            <button type="submit" class="prime-button button-primary button-block margin-top-thirty">{{ isNew ? "Add New Practice" : "Save Updates" }}</button>
          </form>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import states from "@/assets/languagePack/states";
import { US as timezones } from "@/assets/languagePack/timeZones";
import ErrorNotification from "@/components/Global/ErrorNotification";
import ModalAlert from "@/components/Global/ModalAlert";
import PrimePhoneInput from "@/components/Global/PrimePhoneInput";
import Tooltip from "@/components/Global/Tooltip";
import { send as httpSend } from "@/services/Api";
import getOrganizationSettings from "@/services/Api/getOrganizationSettings";
import saveOrganizationSettings from "@/services/Api/saveOrganizationSettings";
import isValidEmail from "@/utils/FormHelpers/isValidEmail";
import { debounce } from "lodash";

export default {
  name: "GeneralSettings",
  components: {
    PrimePhoneInput,
    ErrorNotification,
    Tooltip
  },
  data() {
    return {
      orgId: 0,
      practice: {
        name: undefined,
        orgOutreachAlias: undefined,
        address1: undefined,
        address2: undefined,
        city: undefined,
        state: undefined,
        officeNumber: undefined,
        supportPhoneNumber: undefined,
        email: undefined,
        timeZone: "US/Central",
        isEngagementEnabled: "FALSE",
        reasonForShutdown: undefined,
        myPCPList: {},
        workflowStartTime: undefined,
        cutOff: undefined,
        workflowRunFrequency: "DAILY",
        workflowRunWeek: "MF",
        outreachPhoneNumber: undefined
      },
      originalEmail: undefined,
      pcps: [],
      workflowIsBeingDisabled: false,
      updateLoading: false,
      pageIsLoading: true,
      errors: [],
      copy: this.$languagePack.practiceSettings,
      states: states.codes,
      timezones: timezones
    };
  },
  computed: {
    isNew() {
      return this.orgId === "new";
    },
    title() {
      return this.isNew ? "Add Practice to LumerisEngage" : `Practice Settings - ${this.practice.name}`;
    },
    initiateOutreachAt: {
      get() {
        const startVal = this.practice.workflowStartTime;
        if (!startVal) return;
        return startVal.length === 4 ? "0" + startVal : startVal;
      },
      set(val) {
        this.practice.workflowStartTime = val;
      }
    },
    stopResponsesAt: {
      get() {
        const cutOff = this.practice.cutOff;
        if (!cutOff) return;
        return cutOff.length === 1 ? "0" + cutOff + ":00" : cutOff + ":00";
      },
      set(val) {
        this.practice.cutOff = val.split(":")[0];
      }
    },
    stayInTouchEnabled: {
      get() {
        return this.practice.isEngagementEnabled === "TRUE" ? true : false;
      },
      set(val) {
        this.practice.isEngagementEnabled = val ? "TRUE" : "FALSE";
      }
    },
    reason: {
      get() {
        if (!this.workflowIsBeingDisabled) {
          return "";
        } else {
          return this.org.reasonForShutdown;
        }
      },
      set(val) {
        if (!this.workflowIsBeingDisabled) {
          this.org.reasonForShutdown = "";
        } else {
          this.org.reasonForShutdown = val;
        }
      }
    },
    sendEmailEnabled() {
      return this.practice.email === this.originalEmail;
    }
  },
  created() {
    this.fetchOrganizationSettings();
  },
  activated() {
    this.fetchOrganizationSettings();
  },
  deactivated() {
    this.errors = [];
    this.workflowIsBeingDisabled = false;
  },
  methods: {
    fetchOrganizationSettings: debounce(
      async function() {
        this.orgId = this.$route.params.id;
        if (!this.orgId) {
          return;
        }
        if (!this.isNew) {
          this.pageIsLoading = true;
          this.practice = await getOrganizationSettings(this.orgId);
          this.originalEmail = this.practice.email;
          const pcps = this.practice.myPCPList || {};
          this.pcps = Object.entries(pcps).map(([key, value]) => ({
            id: key,
            displayName: value,
            value
          }));
        }
        this.pageIsLoading = false;
      },
      1000,
      { leading: true, trailing: false }
    ),
    handleStayInTouchEnabledToggle(event) {
      if (event.detail.checked == this.stayInTouchEnabled) {
        return;
      }
      if (!event.detail.checked) {
        this.$ionic.alertController
          .create({
            header: "You are disabling the Stay In Touch Workflow",
            message: "Are you sure?",
            mode: "ios",
            buttons: [
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {
                  setTimeout(() => this.setToggle(true), 100);
                }
              },
              {
                text: "Disable",
                handler: () => {
                  setTimeout(() => this.setToggle(false), 100);
                }
              }
            ]
          })
          .then(a => a.present());
      } else {
        this.workflowIsBeingDisabled = false;
        this.stayInTouchEnabled = event.target.checked;
      }
    },
    setToggle(val) {
      this.stayInTouchEnabled = val;
      this.$refs.workflowToggle.checked = val;
      this.workflowIsBeingDisabled = !val;
    },
    async submitOrganizationSettings() {
      this.updateLoading = true;
      try {
        await saveOrganizationSettings(this.practice);
        if (this.isNew) {
          this.$mypcpRouter.push({ name: "PracticeOverview" });
        } else {
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "Practice Successfully Updated",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          // set originalEmail to saved value
          this.originalEmail = this.practice.email;
        }
      } finally {
        this.updateLoading = false;
      }
    },
    validateAndSubmit() {
      const validFields = this.validateSubmission();
      if (validFields) {
        this.validateWorkflowStatusAndSubmit();
      }
    },
    validateSubmission() {
      const { name, address1, city, state, zipCode, officeNumber, supportPhoneNumber, email, isEngagementEnabled, reasonForShutdown, myPCPList, workflowStartTime, cutOff, workflowRunFrequency, workflowRunWeek, phoneNumber } = this.practice;
      this.errors = [];

      if (!name) {
        this.errors.push("Practice Name is required");
      }
      if (!address1) {
        this.errors.push("Address Line 1 is required");
      }
      if (!city) {
        this.errors.push("City is required");
      }
      if (!state) {
        this.errors.push("State is required");
      }
      if (!zipCode) {
        this.errors.push("Zip Code is required");
      }
      if (!officeNumber) {
        this.errors.push("Office Phone Number is required");
      }
      if (!supportPhoneNumber) {
        this.errors.push("Phone Number for LumerisEngage Support is required");
      }
      if (!isValidEmail(email)) {
        this.errors.push("A valid Email Address is required for task notifications");
      }
      if (!workflowRunFrequency) {
        this.errors.push("Outreach Frequency is required");
      }
      if (!workflowRunWeek) {
        this.errors.push("Outreach Days is required");
      }

      if (isEngagementEnabled === "TRUE") {
        this.pcps.forEach(pcp => {
          if (!pcp.value) {
            this.errors.push("Display Name required for " + pcp.displayName);
          } else {
            myPCPList[pcp.id] = pcp.value;
          }
        });

        if (!workflowStartTime) {
          this.errors.push("Initiate Daily Outreach At is required");
        }
        if (!cutOff) {
          this.errors.push("Stop Incoming Responses At is required");
        }
        if (!phoneNumber) {
          this.errors.push("Outreach Phone Number is required");
        }
      }

      if (this.workflowIsBeingDisabled && !reasonForShutdown) {
        this.errors.push("No reason for disabling workflow specified");
      }

      if (this.errors.length > 0) {
        document.querySelector(".container").scrollTop = 0;
        return false;
      }
      return true;
    },
    validateWorkflowStatusAndSubmit() {
      this.submitOrganizationSettings();
    },
    sendTestEmail() {
      if (!this.sendEmailEnabled) {
        return;
      }
      const method = "get";
      const path = document.config.sendTestEmail;
      this.sendingEmail = true;

      httpSend({ path, method, authToken: true })
        .then(() => {
          this.$ionic.modalController
            .create({
              component: ModalAlert,
              componentProps: {
                propsData: {
                  title: "Send Test Email",
                  message: "Email will be sent momentarily. Please check your inbox and check your spam folder if it does not appear within 5 minutes."
                }
              }
            })
            .then(m => m.present());
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Failed to send test email",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.sendingEmail = false;
        });
    }
  }
};
</script>

<style scoped>
form {
  margin-bottom: 2rem;
}

form h5 {
  margin-top: 40px;
}

.city {
  display: inline-block;
  width: 73%;
}

.state {
  display: inline-block;
  width: 25%;
  margin-left: 2%;
}

.send-email {
  font-size: 11px;
  font-weight: 700;
  margin-top: 3px;
  color: var(--ion-color-primary);
}

.send-email.disabled {
  color: var(--ion-color-medium);
}
.margin-bottom-eighty {
  margin-bottom: 80px;
}
</style>
