import { send } from "@/services/Api";
import store from "@/store";

const updateOrganizationSettings = async data => {
  const method = data.id ? "put" : "post";
  const path = document.config.organizationSettings[method];
  const payload = data;
  try {
    const results = await send({ path, method, authToken: true, payload });
    return results;
  } catch (error) {
    const errorDetails = {
      header: "There was an issue updating organization details.",
      message: error
    };
    store.commit("errorHandler/toastableError", errorDetails);
    throw error;
  }
};

export default updateOrganizationSettings;
